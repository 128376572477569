.main {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 49px;
    left: 0;
}

.main-scrollable {
    margin-bottom: 49px;
}

.main-fixed {
    top: 0;
    right: 0;
    bottom: 49px;
    left: 0;
}

@media screen and (orientation: landscape) {
    .main {
        bottom: 0;
        left: 60px;
    }

    .main-scrollable {
        margin-left: 60px;
        margin-bottom: 0;
    }

    .main-fixed {
        bottom: 0;
        left: 60px;
    }
}

.btn-reset {
    font-family: 'Helvetica Neue', 'Helvetica', arial, sans-serif;
    display: inline-block;
    padding: 0;
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 0;
    border-radius: 0;
    -webkit-appearance: button;
    color: #fff;
}

.btn {
    padding: 6px 12px;
    text-align: center;
    color: #fff;
    border: 1px solid;
    transition: all 0.2s ease;
}

.btn-green {
    background-color: #5cb85c;
    border-color: #4cae4c;
}

.btn-green:hover,
.btn-green:active {
    background-color: #449d44;
    border-color: #398439;
}

.btn-green:focus {
    background-color: #449d44;
    border-color: #255625;
}

.btn-blue {
    background-color: #2e81b6;
    border-color: #1e77a6;
}
.btn-blue.selected {
    background-color: #16669e;
    border-color: #1e77a6;
}

.btn-blue:hover,
.btn-blue:active {
    background-color: #16669e;
    border-color: #0b4d93;
}

.btn-blue:focus {
    background-color: #16669e;
    border-color: #001f7f;
}

.btn-red {
    background-color: #E31937;
    border-color: #ce1733;
}

.btn-red:hover,
.btn-red:active {
    background-color: #aa132a;
    border-color: #8b1124;
}

.btn-red:focus {
    background-color: #aa132a;
    border-color: #700d1c;
}

.btn-spinner {
    color: transparent;
    position: relative;
}

@keyframes rotation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }
}

.btn-spinner:after {
    animation: rotation 0.7s infinite linear;
    border: 3px solid rgba(255, 255, 255, 0.5);
    border-top-color: #fff;
    border-radius: 50%;
    content: '';
    display: block;
    height: 14px;
    left: 50%;
    margin-left: -10px;
    position: absolute;
    top: 6px;
    width: 14px;
}

.hide {
    display: none !important;
}

.fixed-info-wrapper {
    max-width: 400px;
}

@media screen and (min-width: 400px) {
    .fixed-info-wrapper {
        left: 50%;
        margin-left: -200px;
    }
}

#updater {
    background-color: #fbe0d3;
    border: 1px solid #e96525;
    animation: 1s updater-puls infinite;
}
#updater > a {
    padding: 12px;
    color: #e96525;
}
@keyframes updater-puls {
    0% {
        background-color: #fbe0d3;
    }
    50% {
        background-color: #fdece5;
    }
    100% {
        background-color: #fbe0d3;
    }
}
