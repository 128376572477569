@import './hemswx.css';
@import '@esri/calcite-components/dist/calcite/calcite.css';

.v-mid {
    vertical-align: middle;
}

#menu {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    height: auto;
    transition: height 0.2s;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
    overflow-y: hidden;
    overflow-x: hidden;
    height: 49px;
}

#menu.open {
    height: auto;
}
.disclaimer{
    pointer-events: none;
    margin: auto;
    width: 50%;
    text-align: center;
}

.disclaimer-critical {
    color: #ff0000;
}
.disclaimer-warn {
    color: #f5842b;
}

#menu-list {
    display: flex;
    flex-wrap: wrap;
}

.menu-item {
    padding: 0 10px;
    text-align: center;
    display: block;
    height: 49px;
    overflow: hidden;
    width: 80px;
}

.menu-item:hover,
.menu-item:hover * {
    background-color: #f2f6f9 !important;
}

.menu-item-selected,
.menu-item-selected * {
    background-color: #e6edf4 !important;
}

.menu-item-trigger {
    font-size: 12px;
    line-height: 12px;
    display: block;
    border: 0;
    text-align: center;
    width: 100%;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: top 3px center;
    background-size: 40%;
    padding: 30px 0 5px;
    text-decoration: none;
    cursor: pointer;
}

.menu-item-trigger:hover {
    color: #004b8d;
}

.logo-link {
    padding-left: 5px;
    margin-bottom: 16px;
}

.logo-img {
    height: 24px;
}

.menu-icon-menu {
    background-image: url(../img/menu/menu2.svg);
}

.menu-icon-menu-blue,
.menu-icon-menu:hover {
    background-image: url(../img/menu/menu2-blue.svg);
}

/* ikoner i menyen */
.menu-icon-index {
    background-image: url(../img/menu/index2.svg);
}

.menu-icon-index-blue,
.menu-icon-index:hover {
    background-image: url(../img/menu/index2-blue.svg);
}
.menu-icon-met {
    background-image: url(../img/menu/met.svg);
}

.menu-icon-met-blue,
.menu-icon-met:hover {
    background-image: url(../img/menu/met-blue.svg);
}
.menu-icon-sigmet {
    background-image: url(../img/menu/sigmet3.svg);
}

.menu-icon-sigmet-blue,
.menu-icon-sigmet:hover {
    background-image: url(../img/menu/sigmet3-blue.svg);
}

.menu-icon-analyse {
    background-image: url(../img/menu/analyse2.svg);
}

.menu-icon-analyse-blue,
.menu-icon-analyse:hover {
    background-image: url(../img/menu/analyse2-blue.svg);
}

.menu-icon-iga {
    background-image: url(../img/menu/iga3.svg);
}

.menu-icon-iga-blue,
.menu-icon-iga:hover {
    background-image: url(../img/menu/iga3-blue.svg);
}

.menu-icon-info {
    background-image: url(../img/menu/info2.svg);
}

.menu-icon-info-blue,
.menu-icon-info:hover {
    background-image: url(../img/menu/info2-blue.svg);
}

.menu-icon-kystvarsel {
    background-image: url(../img/menu/kystvarsel3.svg);
}

.menu-icon-kystvarsel-blue,
.menu-icon-kystvarsel:hover {
    background-image: url(../img/menu/kystvarsel3-blue.svg);
}

.menu-icon-ops-room {
    background-image: url(../img/menu/ops-room2.svg);
}

.menu-icon-ops-room-blue,
.menu-icon-ops-room:hover {
    background-image: url(../img/menu/ops-room2-blue.svg);
}

.menu-icon-new-opsroom {
    background-image: url(../img/menu/ops-room2.svg);
}
.menu-icon-new-opsroom-blue,
.menu-icon-new-opsroom:hover {
    background-image: url(../img/menu/ops-room2-blue.svg);
}

.menu-icon-radar {
    background-image: url(../img/menu/radar3.svg);
}

.menu-icon-radar-blue,
.menu-icon-radar:hover {
    background-image: url(../img/menu/radar3-blue.svg);
}

.menu-icon-satelitt {
    background-image: url(../img/menu/satelitt2.svg);
}

.menu-icon-satelitt-blue,
.menu-icon-satelitt:hover {
    background-image: url(../img/menu/satelitt2-blue.svg);
}

.menu-icon-sigwx {
    background-image: url(../img/menu/sigwx2.svg);
}

.menu-icon-sigwx-blue,
.menu-icon-sigwx:hover {
    background-image: url(../img/menu/sigwx2-blue.svg);
}

.menu-icon-route-forecast {
    background-image: url(../img/menu/route-forecast2.svg);
}

.menu-icon-route-forecast-blue,
.menu-icon-route-forecast:hover {
    background-image: url(../img/menu/route-forecast2-blue.svg);
}

.menu-icon-info-map {
    background-image: url(../img/menu/info-map.svg);
}

.menu-icon-info-map-blue,
.menu-icon-info-map:hover {
    background-image: url(../img/menu/info-map-blue.svg);
}
.menu-icon-third-party-services {
    background-image: url(../img/menu/third-party-services-2.svg);
}

.menu-icon-third-party-services-blue,
.menu-icon-third-party-services:hover {
    background-image: url(../img/menu/third-party-services-2-blue.svg);
}
.menu-icon-map-images {
    background-image: url(../img/menu/map-images.svg);
}

.menu-icon-map-images-blue,
.menu-icon-map-images:hover {
    background-image: url(../img/menu/map-images-blue.svg);
}
.menu-icon-loggut {
    background-image: url(../img/menu/loggut.svg);
}

.menu-icon-loggut-blue,
.menu-icon-loggut:hover {
    background-image: url(../img/menu/loggut-blue.svg);
}

.menu-icon-dispatch {
    background-image: url(../img/menu/dispatch2.svg);
}

.menu-icon-dispatch-blue,
.menu-icon-dispatch:hover {
    background-image: url(../img/menu/dispatch2-blue.svg);
}
.menu-icon-dispatch2 {
    background-image: url(../img/menu/dispatch3.svg);
}

.menu-icon-dispatch2-blue,
.menu-icon-dispatch2:hover {
    background-image: url(../img/menu/dispatch3-blue.svg);
}

.menu-icon-crew-manifest {
    background-image: url(../img/menu/crew2.svg);
}

.menu-icon-crew-manifest-blue,
.menu-icon-crew-manifest:hover {
    background-image: url(../img/menu/crew2-blue.svg);
}
.menu-icon-statistics {
    font-weight: 800;
    background-image: url(../img/menu/statistics.svg);
}
.menu-icon-statistics-blue,
.menu-icon-statistics:hover {
    background-image: url(../img/menu/statistics-blue.svg);
}
.menu-icon-open {
    background-image: url(../img/menu/right-arrow.svg);
}

.menu-icon-open:hover {
    background-image: url(../img/menu/right-arrow.svg);
}
.menu-icon-admin {
    background-image: url(../img/menu/admin.svg);
}
.menu-icon-admin-blue,
.menu-icon-admin:hover {
    background-image: url(../img/menu/admin-blue.svg);
}

.open .menu-icon-open {
    background-image: url(../img/menu/left-arrow.svg);
}

@media (orientation: portrait) {
    /* stops transform style from JS in portrait mode */
    .menu-item-trigger {
        transform: none !important;
    }

    .show-only-landscape {
        display: none !important;
    }
}

@media (orientation: landscape) {
    #menu {
        top: 0;
        right: auto;
        bottom: 0;
        height: auto;
        padding-top: 1rem;
        transition: width 0.2s;
        overflow-x: visible;
        overflow-y: visible;
        height: auto;
        width: 60px;
        -ms-overflow-style: none;
    }

    #menu::after {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 30px;
        background-image: linear-gradient(transparent, #fff 50%, #fff 100%);
    }

    #menu.open {
        width: 214px;
    }

    #menu-list {
        overflow-x: hidden;
        overflow-y: auto;
        height: 100%;
        flex-direction: column;
        flex-wrap: nowrap;
    }

    #menu-list::after {
        display: block;
        content: '';
        width: 60px;
        height: 14px;
        flex-shrink: 0;
    }

    #menu-list::-webkit-scrollbar {
        display: none;
    }

    .menu-item {
        text-align: left;
        overflow: visible;
        height: 44px;
        margin-bottom: 16px;
        width: 60px;
        padding: 0 0 0 14px;
        flex-shrink: 0;
    }

    .menu-item-trigger {
        background-position: center left;
        padding: 0 0 0 40px;
        font-size: 16px;
        width: 200px;
        background-color: #fff;
        text-align: left;
        background-size: 15%;
        height: 100%;
    }

    .menu-item-trigger > span {
        display: none;
        background-color: #fff;
        padding: 1rem;
        width: 160px;
        position: absolute;
    }

    .menu-item-trigger:hover > span {
        display: block;
        background-color: #fff;
        padding: 1rem;
        width: 160px;
        box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
    }

    .open .menu-item-trigger > span {
        display: block;
        position: static;
        transform: none !important;
        margin-top: 0;
    }

    .open .menu-item-trigger:hover > span {
        box-shadow: none;
    }

    button.menu-item-trigger > span {
        margin-top: -22px;
    }

    .hide-landscape {
        display: none;
    }

    .show-only-landscape {
        display: block;
    }
}
